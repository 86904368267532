<template>
  <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="p-0 pt-1 card-header">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="px-3 pt-2"><h3 class="card-title">{{ product.name}}</h3></li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="true" :class="{'disabled': !product.article}">Artikel</a>
                            </li> -->
                            <li class="nav-item">
                                <a href="#custom-tabs-four-ingredients" class="nav-link" id="custom-tabs-four-ingredients-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-ingredients" aria-selected="true">Materialien</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-price" class="nav-link" id="custom-tabs-four-price-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-price" aria-selected="true">Produktionskosten</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <General :product="product" :units="units" :labels="labels" v-if="notEmptyObject(product) && notEmptyObject(labels) && notEmptyObject(units)" />
                            </div>
                            <!-- <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                <Article :article="product.article" v-if="product.article != null" />
                            </div> -->
                            <div class="tab-pane fade" id="custom-tabs-four-ingredients" role="tabpanel" aria-labelledby="custom-tabs-four-ingredients">
                                <Recipe :product="product" v-if="notEmptyObject(product)" />                
                            </div>
                             <div class="tab-pane fade" id="custom-tabs-four-price" role="tabpanel" aria-labelledby="custom-tabs-four-price">
                                <Price :product="product" v-if="notEmptyObject(product)" />                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import General from './components/General';
import Recipe from './components/Recipe';
import Price from './components/Price';

export default {
    components:{
        General,
        Recipe,
        Price
    },
    name: "ProductDetail",
    title: "Produkt bearbeiten",
    data() {
        return {
            product: {},
            units: {},
            labels: {},
        }
    },

    methods: {        
        loadProduct() {
            this.axios
                .get("/products/" + this.$route.params.id)
                .then((response) => {
                    this.product = response.data.data;
                    // this.ingredients = this.product.ingredients;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadUnits() {
            this.axios
                .get("/units")
                .then((response) => {
                    this.units = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadLabels()
        {
            this.axios
                .get("/labels/product")
                .then((response) => {
                    this.labels = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.loadProduct();
        this.loadUnits();
        this.loadLabels();
    },


}
</script>

<style>

</style>